import React from 'react';

import { Container, Center } from '../../../pageStyles';
import { Button } from './styled';

export const NextButton = props => {
    return (
        <Container>
            <Center>
                <Button onClick={props.next}>
                    {props.label ? props.label : 'Avanti'}
                </Button>
            </Center>
        </Container>
    )
}