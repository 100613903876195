import React, { useState } from 'react';

import StepWizard from 'react-step-wizard';

import { Container } from '../../pageStyles'
import { Welcome } from './Welcome';
import { Results } from './Results';
import { Budget } from './Budget';
import { Progress } from './Progress';
import { Radio, SmallRadio, Micro, LongRange, MultiProto, Goggles, Digital, GoPro } from './Question';
import { Style } from './Choice';

import { NextButton } from './NextButton';


var config = require("../../data/configurator.json");


const Wizard = () => {
    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: "animated enterRight",
            exitLeft: "animated exitLeft",
            intro: "animated",
        },
    });

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;

    return (
            <Container>
                <StepWizard
                    isHashEnabled
                    transitions={state.transitions} // comment out for default transitions
                    instance={setInstance}
                >
                    <Info hashKey={'info'} update={updateForm} />
                    <Budget hashKey={'budget'} form={state.form} update={updateForm} />
                    <Radio hashKey={'radiocomando'} form={state.form} update={updateForm} />
                    {state.form.radio === "no" ? 
                        <SmallRadio hashKey={'dimensioni'} form={state.form} update={updateForm} /> 
                    : null}
                    {state.form.radio === "no" ?
                        <Micro hashKey={'tiny-whoops'} form={state.form} update={updateForm} /> 
                    : null}
                    {state.form.radio === "no" ? 
                        <LongRange hashKe   y={'long-range'} form={state.form} update={updateForm} /> 
                    : null}
                    {state.form.radio === "no" ?
                        <MultiProto hashKey={'multi-protocollo'} form={state.form} update={updateForm} /> 
                    : null}

                    <Goggles hashKey={'occhiali'} form={state.form} update={updateForm} />
                    {(state.form.goggles === "no" && state.form.budget.min > 800) ? 
                            <Digital hashKey={'digitale'} form={state.form} update={updateForm} /> 
                    : null}
                    <Style hashKey={'stile'} form={state.form} update={updateForm} />
                    <GoPro hashKey={'gopro'} form={state.form} update={updateForm} /> 
                    <Progress hashKey={'elaborazione'} />
                    <Last hashKey={'prodotti'} form={state.form} />
                </StepWizard>
        </Container>
    );
};

export default Wizard;


/** Steps */

const Info = props => {
    return (
        <>
            <Welcome action={<NextButton next={props.nextStep} label={"Inizia Subito"}/>}/>
            <NextButton next={props.nextStep} label={"Prova ora"}/>
        </>
    );
};


const Last = (props) => {
    const raccomandations = () => {
        let products = [];

        if (props.form.budget.max < 170) products.push(config.kit['emax-indoor'])
        else if (props.form.budget.max < 200) products.push(config.kit['betafpv-kit'])
        else {

            const radio = radioChoice(props.form);
            products.push(config.radio[radio.key]);

            const goggles = gogglesChoice(props.form);
            products.push(config.goggles[goggles.key]);

            const drone = droneChoice(props.form);
            products.push(config.drone[drone.key]);

        }
        return products
    };

    const radioChoice = (data) => {
        // Se ha già la radio, niente da fare
        if (data.radio === "yes") return {};

        // Poco Budget
        if (data.budget.max < 250) return { key: "betafpv", price: 50 }

        if ((data.smallRadio) && (data.smallRadio === "piccolo")) {
            // Buon budget, solo crossfire
            if ((data.budget.min > 500) && (data.range === "yes") && (data.multi === "no")) return { key: "tango", price: 250 }

            if ((data.budget.max > 400) && (data.range === "no")) return { key: "x-lite", price: 150 }

        }
        // No portabilità

        // Multi protocolli
        if ((data.budget.max > 400) || (data.multi === "yes")) return { key: "radiomaster", price: 130 }

        if (data.budget.max > 550) return { key: 'x9d-plus', price: 200 }
        return { key: "qx7", price: 100 }
    }

    const gogglesChoice = (data) => {
        // Se ha già gli occhiali, niente da fare
        if (data.goggles === "yes") return {};

        if (data.budget.max < 250) return { key: 'eachine-ev800d', price: 100 }

        if (data.digital && data.digital === "yes" && data.budget.max > 850) return { key: 'dji', price: 450 }

        if (data.budget.max > 850) return { key: 'fatshark-hdo2', price: 400 }

        if (data.budget.min > 450) return { key: 'eachine-ev200d', price: 300 }

        if (data.budget.min > 350) return { key: 'skyzone02', price: 250 }

        return { key: 'eachine-ev800d', price: 100 }
    }

    const droneChoice = (data) => {

        // if (data.build === "yes") {

        // }
        // else {
            if (data.micro === "yes") {
                if (data.budget.max < 300) return { key: 'betafpv85x' }
            }
            if (data.cinematic && !data.freestyle && !data.racing && !data.long_range) {

                if (data.gopro === "yes") {
                    // CineWhoop
                    if (data.budget.max > 700) return data.digital ? { key: 'bumblebee-v2-hd' } : { key: 'bumblebee-v2' }
                    if (data.budget.max > 600 && !data.digital) return { key: 'hornet' }
                    return data.digital ? { key: 'taycan25-hd' } : { key: 'betafpv95x' }
                }
                else {
                    // BetaFPV
                    return data.digital ? { key: 'betafpv95x-hd' } : { key: 'betafpv95x' }
                }

            }
            if (data.freestyle || data.long_range) {

                if (data.gopro === "yes") {
                    // Freestyle/LongRange
                    if (data.budget.max > 1200 && data.digital) return { key: 'chimera7-hd' }
                    if (data.budget.max > 1000) return data.digital ? { key: 'titan-xl5-hd' } : { key: 'titan-xl5' }
                    if (data.budget.max > 850) return data.digital ? { key: 'nazgul5-hd' } : { key: 'nazgul5' }
                    if (data.budget.max > 750 && !data.digital) return { key: 'cidora-sl5' }
                    if (data.budget.max > 650 && !data.digital) return { key: 'cidora-sl5-e' }
                    if (data.budget.max > 550 && !data.digital) return { key: 'lal5' }
                }
                else {
                    if (data.long_range) return data.digital ? { key: 'explorer-lr4-hd' } : { key: 'explorer-lr4' }
                    if (data.budget.max > 600) return data.digital ? { key: 'gt-r349-hd' } : { key: 'gt-r349' }
                    if (data.budget.max > 350) return { key: 'tyro89' }
                }
            }

            if (data.racing) {
                return { key: 'tryo119' }
            }
        // }
        return { key: 'cidora-sl5' }

    }

    return <Results raccomandations={raccomandations()} />
};