import React from 'react';

import {
    Grid,
    Heading,
    SubHeading,
    Paragraph,
} from '../../../pageStyles'

import BuyCard from '../../common/BuyCard';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag'

export const Results = ({raccomandations}) => {
    const data = useStaticQuery(graphql`
    {
        site{
            siteMetadata {
                social {
                    facebook
                    instagram
                    discord
                }
            }
        }
    }
    `);

    return (
        <div>
                <SubHeading>Ricordati</SubHeading>
                <Paragraph>Puoi sostenere gratuitamente il progetto di NorthFPV semplicemente acquistando attreverso i OutboundLink qui sotto.</Paragraph>
                <Heading>Ecco fatto!</Heading>
                <Paragraph>Sulla base delle tue preferenze e del tuo budget, questi sono i prodotti che ti consigliamo di acquistare. Il consiglio è sempre quello di acquistare come prima cosa il radiocomando. Una volta che hai la radio puoi allenarti sul simulatore, NorthFPV consiglia <OutboundLink href="https://velocidrone.com">Velocidrone</OutboundLink>.</Paragraph>
                <Grid>
                    {raccomandations.map((product) => {
                        return <BuyCard product={product} />
                    })}
                </Grid>
                <SubHeading>Perchè il simulatore?</SubHeading>
                <Paragraph>Perchè volare in FPV è difficile e non puoi fare danni! Se crashi, non rompi niente e non spendi soldi per riparare. Inoltre è divertente e puoi giocare con i ragazzi di NorthFPV entrando nel nostro server su <OutboundLink href={data.site.siteMetadata.discord}>Discord</OutboundLink>.</Paragraph>
                <SubHeading>Perchè un drone assemblato?</SubHeading>
                <Paragraph>Per divertirti subito! Un drone è difficile da costruire e fa parte dell'hobby. Avrai sicuramente modo di imparare tutto quando dovrai metter mano al tuo drone per ripararlo.</Paragraph>
                <Paragraph>Se vuoi avere maggiori informazioni su questi prodotti contattaci su <OutboundLink href={data.site.siteMetadata.facebook}>Facebook</OutboundLink> o <OutboundLink href={data.site.siteMetadata.instagram}>Instagram</OutboundLink></Paragraph>
        </div>
    );
}