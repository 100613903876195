import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

import { CallToAction } from '../../../pageStyles'

export const Button = styled(CallToAction)`
    margin: 2rem 0;
    padding-right: 8rem;
    padding-left: 8rem;

    ${media.medium`
        margin: 2rem 20%;
    `}
`;