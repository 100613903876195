import React from 'react'

import { Layout, SEO } from '../../components/common'
import Wizard from '../../components/wizard/Step'
import { TelegramChannel } from '../../components/common/Promo';

const FPVStarter = props => {
    return (
        <>
            <Layout isHome={false} promotion={<TelegramChannel/>} >
                <SEO
                    title="FPV Assistant - Scegli i migliori componenti FPV"
                    description="Il primo FPV assistant che ti aiuta a scegliere i componenti migliori per volare con un drone FPV."
                />
                <Wizard/>
            </Layout>
        </>
    )
}

export default FPVStarter;
