import React, { useState } from 'react';
import InputRange from 'react-input-range';

import { Heading, SubHeading, Paragraph } from '../../../pageStyles'
import { BudgetContainer } from './styled';

import { Question } from '../styled';

import { NextButton } from '../NextButton';

const useConstructor = (callBack, props) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack(props);
    setHasBeenCalled(true);
  }
  
const BudgetRange = (props) => {
    const initState = {
        value: { min: 500, max: 800 },
    };

    useConstructor((props) => props.update('budget', initState), props);
    
    const [state, setState] = useState(initState);

    const onChangeValue = (value) => {
        setState({ value })
        props.update('budget', value);
    }

    
    return (
        <BudgetContainer>
            tra {state.value.min} e {state.value.max} euro
            <InputRange
                maxValue={1500}
                minValue={50}
                step={50}
                value={state.value}
                onChange={value => onChangeValue(value)} />
        </BudgetContainer>
    );
}

export const Budget = props => {
    const update = (name, value) => {
        props.update(name, value)
    };

    return (
        <Question>
            <Heading>Ciao <span role="img" aria-label="waving">👋</span></Heading>
            <Paragraph>La prima cosa da scegliere è il tuo budget.</Paragraph>
            <Paragraph>L'FPV è un hobby abbastanza costosto, per iniziare bene tipicamente si spende tra 500 e 800 euro. Tuttavia, negli ultimi mesi stanno uscendo alternative sempre più economiche.</Paragraph>
            <SubHeading>Seleziona il tuo budget muovendo le due estremità</SubHeading>
            <label htmlFor="budget">Il budget scelto è </label>
            <BudgetRange update={update} />
            <NextButton next={props.nextStep} />
        </Question>
    );
};