import React from 'react';

import { SubHeading, Paragraph } from '../../../pageStyles';
import { Question, AnswerContanier, Option, OptionLabel, OptionContainer } from './styled'

const YesOrNo = props => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
        props.nextStep();
    };
    return (
        <Question>
            <label htmlFor={props.id}><SubHeading>{props.question}</SubHeading></label>
            {props.help ? props.help : null}
            <AnswerContanier id={props.id}>
                <OptionContainer>
                    <Option 
                        type="radio" 
                        id={`radio-yes${props.id}`} 
                        name={props.id} 
                        value={props.yes ? props.yes.toString().toLowerCase() : "yes"} 
                        onClick={update} />
                    <OptionLabel htmlFor={`radio-yes${props.id}`}>{props.yes ? props.yes : "Sì"}</OptionLabel>
                </OptionContainer>
                <OptionContainer>
                    <Option 
                        type="radio" 
                        id={`radio-no${props.id}`} 
                        name={props.id} 
                        value={props.no ? props.no.toString().toLowerCase() : "no"} 
                        onClick={update} />
                    <OptionLabel htmlFor={`radio-no${props.id}`}>{props.no ? props.no : "No"}</OptionLabel>
                </OptionContainer>
            </AnswerContanier>
            
        </Question>
    );
};

export const Radio = (props) => {
    const question = "Hai già un radiocomando?"
    const help = 
        <>
            <Paragraph>
                Se hai già un radiocomando rispondi sì, altrimenti se non lo hai, 
                il configuratore ti consiglierà anche un radiocomando per iniziare.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="radio" 
        question={question}
        help={help}
        nextStep={props.nextStep} 
        form={props.form} 
        update={props.update} />
}

export const SmallRadio = (props) => {
    const question = "Vuoi un radiocomando che sia piccolo oppure di dimensione normale?"
    const help = 
        <>
            <Paragraph>
                Le dimensioni contano. 
                Un radiocomando piccolo, anche se tipicamente meno costoso, 
                ha meno precisione e di solito offre meno funzionalità. 
                Tuttavia un vantaggio dei radiocomandi piccoli è la loro facilità nel trasportarli.
            </Paragraph>
            <Paragraph>
                I radiocomandi normali sono quelli più diffusi, facili da usare e più personalizzabili. 
                Inoltre è possibile aggiungere moduli esterni, ad esempio per migliorare la distanza massima di volo.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="smallRadio" 
        question={question}
        help={help}
        hashKey={'small-radio'} 
        yes="Piccolo" 
        no="Normale" 
        nextStep={props.nextStep}
        form={props.form} 
        update={props.update} />
}

export const Micro = (props) => {
    const question = "Vorresti poter pilotare anche tiny whoop?"
    const help = 
        <>
            <Paragraph>
                I tiny whoop sono droni molto piccoli che stanno sul palmo di una mano. 
                Sono ideali per volare in casa, soprattutto nelle giornate invernali.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="micro" 
        question={question}
        help={help}
        hashKey={'micro'} 
        nextStep={props.nextStep}
        form={props.form} 
        update={props.update} />
}

export const LongRange = (props) => {
    const question = "Vorresti fare long range?"
    const help = 
        <>
            <Paragraph>
            Il long range è quella disciplina dell'FPV che mira a fare voli su grandi distanze. 
            I radiocomandi standard, tipicamente ti permettono di arrivare a 500m. 
            Invece con Crossfire, oltre ad avere un collegamento più sicuro al tuo drone, 
            sarai in grado di volare a diversi chilometri di distanza e fare long range.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="range" 
        question={question}
        help={help}
        hashKey={'range'} 
        nextStep={props.nextStep}
        form={props.form} 
        update={props.update} />
}

export const MultiProto = (props) => {
    const question = "Vorresti aver la possibilità di pilotare droni di diverse marche?"
    const help = 
        <>
            <Paragraph>
            Ogni radiocomando si connette con tipo specifico di ricevente. 
            Ultimamente alcuni radicomandi sono venduti con dei moduli multiprotocollo, 
            ovvero che si connettono a diverse riceventi. 
            Questo può essere utile se hai dei droni giocattolo poiché è molto probabile che tu li possa pilotare 
            anche con questo radiocomando.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="multi" 
        question={question}
        help={help}
        hashKey={'multi'} 
        nextStep={props.nextStep}
        form={props.form} 
        update={props.update} />
}

export const Goggles = (props) => {
    const question = "Hai già degli occhiali FPV?"
    const help = 
        <>
            <Paragraph>
            Se hai già un paio di occhiali rispondi sì, altrimenti se non li hai, 
            il configuratore ti consiglierà anche un paio di occhiali per iniziare.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="goggles" 
        question={question}
        help={help}
        hashKey={'occhiali'} 
        nextStep={props.nextStep}
        form={props.form} 
        update={props.update} />
}

export const Digital = (props) => {
    const question = "Vorresti avere un'immagine ad alta risoluzione?"
    const help = 
        <>
            <Paragraph>
            Se il tuo budget lo permette, scegliendo sì il configuratore ti consiglierà il sistema digitale DJI FPV.
            </Paragraph>
        </>
    
    return <YesOrNo 
        id="digital" 
        question={question}
        help={help}
        hashKey={'digitale'}
        nextStep={props.nextStep} 
        form={props.form} 
        update={props.update} />
}

export const GoPro = (props) => {
    return (
        <YesOrNo 
            id="gopro" 
            question="Vuoi montare una GoPro (o un'altra action camera) sul drone?" 
            hashKey={'gopro'} 
            nextStep={props.nextStep}
            form={props.form} 
            update={props.update} />
    );
}