import React from 'react';
import { Container, Heading, Paragraph, SubHeading } from '../../../pageStyles';


export const Welcome = ({action}) => {

    return (
        <Container>
            <Heading>Configuratore FPV</Heading>
            <Paragraph>NorthFPV ha sviluppato il primo configuratore al mondo per droni FPV.</Paragraph>
            <Paragraph>Rispondi alle domande per ottenere i suggerimenti più adatti a te per iniziare a volare con droni FPV.</Paragraph>
            {action ? action : null}
            <SubHeading>Vuoi iniziare a volare con un drone FPV?</SubHeading>
            <Paragraph>Ci sono veramente tante cose da sapere prima di comprare radiocomando, occhiali e un drone FPV.</Paragraph>
            <Paragraph>Spesso seguire un video tutorial su YouTube e seguire i consigli dati in un video non è ideale perché questi consigli tengono conto delle tue esigenze.</Paragraph>
            <Paragraph>Provare a scegliere i pezzi da solo è veramente complicato, soprattutto all'inizio. Spesso si rischia di comprare cose non compatibili oppure di bassa qualità che butterai presto.</Paragraph>
            <Paragraph>Evita di spendere soldi inutili e ascolta i consigli che abbiamo dato ad più di 100 ragazzi come te!</Paragraph>
        </Container>
    );
}