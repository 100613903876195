import React, { useState, useEffect } from 'react';

import { Paragraph } from '../../../pageStyles';

export const Progress = (props) => {
    const [state, updateState] = useState({
        isActiveClass: '',
        timeout: null,
    });

    useEffect(() => {
        const { timeout } = state;

        if (props.isActive && !timeout) {
            updateState({
                isActiveClass: 'loaded',
                timeout: setTimeout(() => {
                    props.lastStep();
                }, 3500),
            });
        } else if (!props.isActive && timeout) {
            clearTimeout(timeout);
            updateState({
                isActiveClass: '',
                timeout: null,
            });
        }
    });

    return (
        <div className='progress-wrapper'>
            <Paragraph>Stiamo cercando i migliori prodotti per te!</Paragraph>
            <div className={`progress-bar ${state.isActiveClass}`}>
                <div className={`progress-bar progress-bar-striped`} />
            </div>
        </div>
    );
};
