import React from 'react';

import { NextButton } from '../NextButton';

import { SubHeading, Paragraph } from '../../../pageStyles';
import { Question } from './styled';

const Choices = props => {
    const update = (e) => {
        props.update(e.target.name, e.target.value);
    };
    const userFriendly = (string) => {
        string = string.replace("_", " ")
        return string.toUpperCase();
    }
    return (
        <Question>
            <label htmlFor={props.id}><SubHeading>{props.question}</SubHeading></label>
            <Paragraph>{props.help}</Paragraph>
            <div className="checkbox-toolbar" id={props.id}>
                {props.choices.map((value) => {
                    return <span className="choice">
                        <input type="checkbox" id={`checkbox-${value}`} name={value} value={value} onClick={update} />
                        <label htmlFor={`checkbox-${value}`}>{userFriendly(value)}</label>
                    </span>
                })}
                <NextButton next={props.nextStep} />
            </div>
            <p>&nbsp;</p>
        </Question>
    );
};

export const Style = props => {

    const question = "Quale stile di volo preferisci?"
    const help = 
        <>
            <Paragraph>
            Ogni drone è pensato per uno stile di volo preciso. 
            </Paragraph>
            <Paragraph>
            Un drone da <strong>racing</strong> sarà veloce, leggero e tipicamente non riuscirai a montare una GoPro. 
            </Paragraph>
            <Paragraph>
            La caratteristica principale di drone da <strong>freestyle</strong> sarà la resistenza agli impatti. 
            </Paragraph>
            <Paragraph>
            Per il volo <strong>cinematic</strong>, tipicamente i droni sono più piccoli e agili, spesso con le eliche protette. 
            </Paragraph>
            <Paragraph>
            Infine per il volo <strong>long range</strong>, la caratteristica principale è l'efficienza. Puoi scegliere più di un'opzione
            </Paragraph>
        </>
    

    return <Choices 
        id="style" 
        question={question}
        help={help}
        choices={["freestyle", "cinematic", "racing", "long_range"]} 
        form={props.form} 
        update={props.update}
        nextStep={props.nextStep} />
}    