import styled from 'styled-components';
import { colors, fonts, media } from '../../utils';

export const Question = styled.div`

`;

export const AnswerContanier = styled.div`
    display: flex;
    flex-direction: column;

    ${media.medium`
        flex-direction: row;
    `}
`;

export const OptionContainer = styled.div`
    display: flex;
    flex: 50%;
`;

export const Option = styled.input`
    opacity: 0;
    position: fixed;
    width: 0;
`;

export const OptionLabel = styled.label`
    display: inline-block;
    padding: 0.5em 1.2em;
    border-radius: 2rem;
    margin: 2rem;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: bold;
    font-size: large;
    background-color: ${colors.personality};
    color: ${colors.white} !important;
    text-align: center;
    transition: all 0.2s;
    text-transform: uppercase;

    &:hover {
        font-weight: bolder;
        text-decoration: none;
    }  
`;